<template>
  <div class="view-content">
    <div v-if="pageLoading" class="text-center p-5">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>
    <div v-else>
      <!-- responsive player area -->
      <div v-if="focusedLesson" class="d-lg-none">
        <div
          class="d-flex align-items-center justify-content-between border-bottom py-4 mb-4"
        >
          <button class="btn angle-btn" @click="previous">
            <i class="icon-angle-left mr-2"></i> Anterior
          </button>
          <button class="btn angle-btn" @click="next">
            Próximo <i class="icon-angle-right ml-2"></i>
          </button>
        </div>
        <div class="player-area">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4>{{ focusedLesson.title }}</h4>
            <button
              class="btn border align-items-center font-weight-light px-4"
              :class="{
                'btn-success': completed
              }"
              @click="markAsCompleted"
            >
              <h5
                class="p-0 m-0 mr-2 rounded-circle"
                :class="{
                  'rounded-cirlce border': !completed
                }"
              >
                <i v-if="completed" class="icon-check" />
              </h5>
              <span class="pb-1">
                {{ completed ? "Completa" : "Marcar como concluído" }}
              </span>
            </button>
          </div>
          <div class="embed-responsive embed-responsive-16by9">
            <div
              id="loading"
              style="position:absolute;top:0;bottom:0;right:0;left:0;"
              class="bg-secondary d-flex justify-content-center align-items-center"
            >
              <h1 class="text-light">...</h1>
            </div>
            <iframe
              class="embed-responsive-item"
              :src="embedUrl"
              frameborder="0"
              allow="autoplay; fullscreen; accelerometer; modestbranding; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              id="vimeo_id_0"
            ></iframe>
          </div>
        </div>
      </div>
      <!-- responsive player -->
      <div class="row">
        <div class="col-lg-4 border-right">
          <div class="py-5 text-center">
            <div class="d-flex align-items-center">
              <radial-progress-bar
                class="mr-2"
                :diameter="130"
                :completed-steps="
                  $user.user &&
                  $user.user.progress &&
                  $user.user.progress.lessons
                    ? $user.user.progress.lessons.length
                    : 0
                "
                :total-steps="lessonsCount || 0"
                startColor="#74AFFC"
                stopColor="#74AFFC"
                innerStrokeColor="#E5E5E5"
                :strokeWidth="4"
                :innerStrokeWidth="5"
              >
                {{ `${lessonAverage}%` }}
              </radial-progress-bar>
              <div class="text-left">
                <div>MÓDULOS</div>
                <small class="text-secondary">{{
                  `${
                    $user.user &&
                    $user.user.progress &&
                    $user.user.progress.lessons
                      ? $user.user.progress.lessons.length
                      : 0
                  } de ${lessonsCount} aulas completas`
                }}</small>
              </div>
            </div>
          </div>
          <div
            v-for="(contentModule, index) in modules"
            :key="`module-${index}`"
            class="border-bottom"
          >
            <button
              class="btn btn-block justify-content-start py-4"
              @click="
                onOpenModule({
                  id: contentModule.id,
                  previousId: modules[index - 1]
                    ? modules[index - 1].id
                    : undefined,
                  nextId: modules[index + 1] ? modules[index + 1].id : undefined
                })
              "
            >
              <div class="text-left flex-fill">{{ contentModule.name }}</div>
              <i
                :class="{
                  'icon-angle-right': opened.id !== contentModule.id,
                  'icon-angle-down': opened.id === contentModule.id
                }"
              ></i>
            </button>
            <div v-if="opened.id === contentModule.id" class="pl-4">
              <div v-for="(lesson, i) in openedLessons" :key="`lesson-${i}`">
                <button
                  class="btn btn-block font-weight-light justify-content-start lesson-item py-2"
                  @click="selectLesson({ ...lesson, listIndex: i })"
                >
                  {{ lesson.title }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="focusedLesson" class="col-lg-8 d-none d-lg-block">
          <div
            class="d-flex align-items-center justify-content-between border-bottom py-4 mb-4"
          >
            <button class="btn angle-btn" @click="previous">
              <i class="icon-angle-left mr-2"></i> Anterior
            </button>
            <button class="btn angle-btn" @click="next">
              Próximo <i class="icon-angle-right ml-2"></i>
            </button>
          </div>
          <div v-if="focusedLesson" class="player-area">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h4>{{ focusedLesson.title }}</h4>
              <button
                class="btn border align-items-center font-weight-light px-4"
                :class="{
                  'btn-success': completed
                }"
                @click="markAsCompleted"
              >
                <h5
                  class="p-0 m-0 mr-2 rounded-circle"
                  :class="{
                    'rounded-cirlce border': !completed
                  }"
                >
                  <i v-if="completed" class="icon-check" />
                </h5>
                <span class="pb-1">
                  {{ completed ? "Completa" : "Marcar como concluído" }}
                </span>
              </button>
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <div
                id="loading"
                style="position:absolute;top:0;bottom:0;right:0;left:0;"
                class="bg-secondary d-flex justify-content-center align-items-center"
              >
                <h1 class="text-light">...</h1>
              </div>
              <iframe
                class="embed-responsive-item"
                :src="embedUrl"
                frameborder="0"
                allow="autoplay; fullscreen; accelerometer; modestbranding; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                id="vimeo_id_0"
              ></iframe>
            </div>
          </div>
        </div>
        <div v-else class="col-lg-8 d-none d-lg-block">
          <div class="d-flex justify-content-center align-items-center h-50">
            <div class="text-secondary">
              Selecione a aula na listagem situada ao lado esquerdo.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentMixin from "@/mixins/content.js";
import { mapState } from "vuex";

export default {
  mixins: [ContentMixin],
  data() {
    return {
      opened: "",
      focusedLesson: null,
      completed: false
    };
  },
  mounted() {
    if (!this.modules.length || !Object.entries(this.lessons).length) {
      this.initData();
    }

    if (this.lesson) {
      this.focusedLesson = this.lesson;
    }
  },
  watch: {
    focusedLesson(v) {
      let progress = null;
      if (this.$user.user && this.$user.user.progress) {
        progress = this.$user.user.progress;
      }
      if (progress && progress.lessons) {
        this.completed = progress.lessons.indexOf(v.id) !== -1;
      } else {
        this.completed = false;
      }
    }
  },
  computed: {
    ...mapState("content", ["modules", "lessons", "lessonsCount", "lesson"]),
    progressCount() {
      let progress = null;
      if (this.$user.user && this.$user.user.progress) {
        progress = this.$user.user.progress;
      }
      if (progress && progress.lessons) {
        return progress.lessons.length;
      }
      return 0;
    },
    lessonAverage() {
      const average = (this.progressCount * 100) / this.lessonsCount;
      return average.toFixed(2);
    },
    openedLessons() {
      if (this.lessons[this.opened.id]) return this.lessons[this.opened.id];
      return [];
    },
    embedUrl() {
      if (!this.focusedLesson) return "";

      return `https://www.youtube.com/embed/${this.getYouTubeVideoID(
        this.focusedLesson.video_url
      )}`;
    }
  },
  methods: {
    markAsCompleted() {
      let isAnRemove = false;
      let progress = {
        lessons: []
      };

      if (this.$user.user && this.$user.user.progress) {
        progress = this.$user.user.progress;
      }

      const index = progress.lessons.indexOf(this.focusedLesson.id);

      if (index === -1) {
        progress.lessons.push(this.focusedLesson.id);
      } else {
        progress.lessons.splice(index, 1);
        isAnRemove = true;
      }

      this.$store
        .dispatch("user/updateProgress", {
          email: this.$user.user.email,
          progress
        })
        .then(() => {
          this.completed = !isAnRemove;
        });
    },
    onOpenModule(moduleInfo) {
      if (this.opened.id === moduleInfo.id) {
        this.opened = {
          id: "",
          previousId: "",
          nextId: ""
        };
        return;
      }
      this.opened = moduleInfo;
    },
    selectLesson(item) {
      this.$store.commit("content/setLesson", item);
      this.focusedLesson = item;
      let progress = {
        lessons: []
      };

      if (this.$user.user && this.$user.user.progress) {
        progress = this.$user.user.progress;
      }

      this.$store
        .dispatch("user/updateProgress", {
          email: this.$user.user.email,
          progress: {
            ...progress,
            lastModuleId: this.opened.id,
            lastLessonId: item.id
          }
        })
        .then();

      window.scrollTo(0, 0);
    },
    getYouTubeVideoID(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    },
    previous() {
      const currentLesson = this.lessons[this.opened.id];
      if (currentLesson) {
        const nextIndex = this.focusedLesson.listIndex - 1;
        if (currentLesson[nextIndex]) {
          this.focusedLesson = {
            ...currentLesson[nextIndex],
            listIndex: nextIndex
          };
        } else {
          if (this.opened.previousId) {
            const nextIndex = this.modules.findIndex(
              m => m.id === this.opened.previousId
            );
            this.opened = {
              id: this.modules[nextIndex].id,
              previousId: this.opened.id,
              nextId: this.modules[nextIndex]
                ? this.modules[nextIndex].id
                : undefined
            };
            this.focusedLesson = {
              ...this.lessons[this.opened.id][
                this.lessons[this.opened.id].length - 1
              ],
              listIndex: this.lessons[this.opened.id].length - 1
            };
          }
        }
      }
    },
    next() {
      const currentModuleLessons = this.lessons[this.opened.id];
      if (currentModuleLessons) {
        const nextIndex = this.focusedLesson.listIndex + 1;
        if (currentModuleLessons[nextIndex]) {
          this.focusedLesson = {
            ...currentModuleLessons[nextIndex],
            listIndex: nextIndex
          };
        } else {
          if (this.opened.nextId) {
            const nextIndex = this.modules.findIndex(
              m => m.id === this.opened.nextId
            );
            this.opened = {
              id: this.modules[nextIndex].id,
              previousId: this.opened.id,
              nextId: this.modules[nextIndex]
                ? this.modules[nextIndex].id
                : undefined
            };
            this.focusedLesson = {
              ...this.lessons[this.opened.id][0],
              listIndex: 0
            };
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.view-content {
  .angle-btn {
    i {
      font-size: 28px;
    }
  }
  button:hover {
    background: #0a184b20;
  }

  .lesson-item:hover {
    color: #44444490;
  }

  .player-area {
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
