var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-content"},[(_vm.pageLoading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary"}})],1):_c('div',[(_vm.focusedLesson)?_c('div',{staticClass:"d-lg-none"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between border-bottom py-4 mb-4"},[_c('button',{staticClass:"btn angle-btn",on:{"click":_vm.previous}},[_c('i',{staticClass:"icon-angle-left mr-2"}),_vm._v(" Anterior ")]),_c('button',{staticClass:"btn angle-btn",on:{"click":_vm.next}},[_vm._v(" Próximo "),_c('i',{staticClass:"icon-angle-right ml-2"})])]),_c('div',{staticClass:"player-area"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-4"},[_c('h4',[_vm._v(_vm._s(_vm.focusedLesson.title))]),_c('button',{staticClass:"btn border align-items-center font-weight-light px-4",class:{
              'btn-success': _vm.completed
            },on:{"click":_vm.markAsCompleted}},[_c('h5',{staticClass:"p-0 m-0 mr-2 rounded-circle",class:{
                'rounded-cirlce border': !_vm.completed
              }},[(_vm.completed)?_c('i',{staticClass:"icon-check"}):_vm._e()]),_c('span',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.completed ? "Completa" : "Marcar como concluído")+" ")])])]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_vm._m(0),_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.embedUrl,"frameborder":"0","allow":"autoplay; fullscreen; accelerometer; modestbranding; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":"","id":"vimeo_id_0"}})])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 border-right"},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('radial-progress-bar',{staticClass:"mr-2",attrs:{"diameter":130,"completed-steps":_vm.$user.user &&
                _vm.$user.user.progress &&
                _vm.$user.user.progress.lessons
                  ? _vm.$user.user.progress.lessons.length
                  : 0,"total-steps":_vm.lessonsCount || 0,"startColor":"#74AFFC","stopColor":"#74AFFC","innerStrokeColor":"#E5E5E5","strokeWidth":4,"innerStrokeWidth":5}},[_vm._v(" "+_vm._s((_vm.lessonAverage + "%"))+" ")]),_c('div',{staticClass:"text-left"},[_c('div',[_vm._v("MÓDULOS")]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(((_vm.$user.user && _vm.$user.user.progress && _vm.$user.user.progress.lessons ? _vm.$user.user.progress.lessons.length : 0) + " de " + _vm.lessonsCount + " aulas completas")))])])],1)]),_vm._l((_vm.modules),function(contentModule,index){return _c('div',{key:("module-" + index),staticClass:"border-bottom"},[_c('button',{staticClass:"btn btn-block justify-content-start py-4",on:{"click":function($event){return _vm.onOpenModule({
                id: contentModule.id,
                previousId: _vm.modules[index - 1]
                  ? _vm.modules[index - 1].id
                  : undefined,
                nextId: _vm.modules[index + 1] ? _vm.modules[index + 1].id : undefined
              })}}},[_c('div',{staticClass:"text-left flex-fill"},[_vm._v(_vm._s(contentModule.name))]),_c('i',{class:{
                'icon-angle-right': _vm.opened.id !== contentModule.id,
                'icon-angle-down': _vm.opened.id === contentModule.id
              }})]),(_vm.opened.id === contentModule.id)?_c('div',{staticClass:"pl-4"},_vm._l((_vm.openedLessons),function(lesson,i){return _c('div',{key:("lesson-" + i)},[_c('button',{staticClass:"btn btn-block font-weight-light justify-content-start lesson-item py-2",on:{"click":function($event){return _vm.selectLesson(Object.assign({}, lesson, {listIndex: i}))}}},[_vm._v(" "+_vm._s(lesson.title)+" ")])])}),0):_vm._e()])})],2),(_vm.focusedLesson)?_c('div',{staticClass:"col-lg-8 d-none d-lg-block"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between border-bottom py-4 mb-4"},[_c('button',{staticClass:"btn angle-btn",on:{"click":_vm.previous}},[_c('i',{staticClass:"icon-angle-left mr-2"}),_vm._v(" Anterior ")]),_c('button',{staticClass:"btn angle-btn",on:{"click":_vm.next}},[_vm._v(" Próximo "),_c('i',{staticClass:"icon-angle-right ml-2"})])]),(_vm.focusedLesson)?_c('div',{staticClass:"player-area"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-4"},[_c('h4',[_vm._v(_vm._s(_vm.focusedLesson.title))]),_c('button',{staticClass:"btn border align-items-center font-weight-light px-4",class:{
                'btn-success': _vm.completed
              },on:{"click":_vm.markAsCompleted}},[_c('h5',{staticClass:"p-0 m-0 mr-2 rounded-circle",class:{
                  'rounded-cirlce border': !_vm.completed
                }},[(_vm.completed)?_c('i',{staticClass:"icon-check"}):_vm._e()]),_c('span',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.completed ? "Completa" : "Marcar como concluído")+" ")])])]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_vm._m(1),_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.embedUrl,"frameborder":"0","allow":"autoplay; fullscreen; accelerometer; modestbranding; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":"","id":"vimeo_id_0"}})])]):_vm._e()]):_c('div',{staticClass:"col-lg-8 d-none d-lg-block"},[_vm._m(2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-secondary d-flex justify-content-center align-items-center",staticStyle:{"position":"absolute","top":"0","bottom":"0","right":"0","left":"0"},attrs:{"id":"loading"}},[_c('h1',{staticClass:"text-light"},[_vm._v("...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-secondary d-flex justify-content-center align-items-center",staticStyle:{"position":"absolute","top":"0","bottom":"0","right":"0","left":"0"},attrs:{"id":"loading"}},[_c('h1',{staticClass:"text-light"},[_vm._v("...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center align-items-center h-50"},[_c('div',{staticClass:"text-secondary"},[_vm._v(" Selecione a aula na listagem situada ao lado esquerdo. ")])])}]

export { render, staticRenderFns }