export default {
  data() {
    return {
      pageLoading: false
    };
  },
  methods: {
    async initData() {
      try {
        this.pageLoading = true;
        const modules = await this.$store.dispatch("content/getModules");
        const lessons = {};
        let count = 0;
        for (let key in modules) {
          const lesson = await this.$store.dispatch(
            "content/getLesson",
            modules[key].id
          );
          lessons[modules[key].id] = lesson;
          count += lesson.length;
        }
        this.$store.commit("content/setLessons", lessons);
        this.$store.commit("content/setLessonsCount", count);
      } catch (e) {
        // console.log(e);
      } finally {
        this.pageLoading = false;
      }
    }
  }
};
